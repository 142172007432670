export enum OperationalSubTaskTypes {
  VERIFY_DOMAIN = 'verify-domain',
  ADD_ORGANIZATION = 'add-organization',
  BRAND_ORGANIZATION = 'brand-organization',
  UPDATE_MASTER_LANGUAGE = 'update-master-language',
  APPROVE_NEW_ORGANIZATION = 'approve-new-organization',

  REGISTER_ACCOUNT = 'register-account',
  LOGIN_FOR_FIRST_TIME = 'login-for-first-time',

  SEND_CHANNEL_SHARE_MESSAGE_TO_EXISTING_USER = 'send-channel-share-message-to-existing-user',
  SEND_CHANNEL_SHARE_MESSAGE_TO_NEW_USER = 'send-channel-share-message-to-new-user',
  VERIFY_VIDEO_PRODUCTION_ORDER = 'verify-video-production-order',
  PRODUCE_VIDEO_PRODUCTION_ORDER = 'produce-video-production-order',
  REVIEW_AND_APPROVE_VIDEO_PRODUCTION_ORDER = 'review-and-approve-video-production-order',
  SUPPORT_TICKET = 'support-ticket',
  UPDATE_CRM_PROFILE = 'update-crm-profile',
  ADD_PLAYLIST_TO_CHANNEL = 'add-playlist-to-channel',
  ADD_VIDEO_PAGE = 'add-video-page',
  UPLOAD_VIDEO = 'upload-video',
  // task REVIEW_PRODUCTION_VIDEO to be deprecated later on
  REVIEW_PRODUCTION_VIDEO = 'review-production-video',
  REVIEW_OPTIMIZED_VIDEO = 'review-optimized-video',
  REQUEST_VIDEO_TRANSLATION = 'request-video-translation',
  TRANSLATE_VIDEO = 'translate-video',
  APPROVE_VIDEO_TRANSLATION = 'approve-video-translation',
  REVIEW_VIDEO_TRANSLATION = 'review-video-translation',
  REPLY_COMMENT_ON_VIDEO = 'reply-comment-on-video',
  ALLOW_VIDEO_ACCESS = 'allow-video-access',
  ASSISTED_SEARCH = 'assisted-search',
  RENDER_VIDEO = 'render-video',
  COMPLETE_VIDEO_OPTIMIZATION = 'complete-video-optimization',
  START_VIDEO_OPTIMIZATION = 'start-video-optimization',
  PUBLISH_ORIGINAL_VIDEO = 'publish-original-video',
  VERIFY_GENERATE_VIDEO_ORDER = 'verify-generate-video-order',
  CREATE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER = 'create-power-point-for-generate-video-order',
  APPROVE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER = 'approve-power-point-for-generate-video-order',
  GENERATE_VIDEO_FOR_ORDER = 'generate-video-for-order',
  APPROVE_GENERATED_VIDEO = 'approve-generated-video',
  REVIEW_GENERATED_VIDEO = 'review-generated-video',
  VERIFY_CP07_VIDEO_ORDER = 'verify-cp07-video-order',
  GENERATE_CP07_VIDEO_FOR_ORDER = 'generate-cp07-video-for-order',
  APPROVE_CP07_VIDEO = 'approve-cp07-video',
  REVIEW_CP07_VIDEO = 'review-cp07-video',
  VERIFY_CP99_VIDEO_ORDER = 'verify-cp99-video-order',
  GENERATE_CP99_VIDEO_FOR_ORDER = 'generate-cp99-video-for-order',
  APPROVE_CP99_VIDEO = 'approve-cp99-video',
  REVIEW_CP99_VIDEO = 'review-cp99-video',
  VERIFY_CP04_VIDEO_ORDER = 'verify-cp04-video-order',
  GENERATE_CP04_VIDEO_FOR_ORDER = 'generate-cp04-video-for-order',
  APPROVE_CP04_VIDEO = 'approve-cp04-video',
  REVIEW_CP04_VIDEO = 'review-cp04-video',
  VERIFY_CP02_VIDEO_ORDER = 'verify-cp02-video-order',
  GENERATE_CP02_VIDEO_FOR_ORDER = 'generate-cp02-video-for-order',
  APPROVE_CP02_VIDEO = 'approve-cp02-video',
  REVIEW_CP02_VIDEO = 'review-cp02-video',
  VERIFY_CP08_VIDEO_ORDER = 'verify-cp08-video-order',
  GENERATE_CP08_VIDEO_FOR_ORDER = 'generate-cp08-video-for-order',
  APPROVE_CP08_VIDEO = 'approve-cp08-video',
  REVIEW_CP08_VIDEO = 'review-cp08-video',
}

export enum GovernanceSubTaskTypes {
  ASSIGN_CHANNEL_OWNER = 'assign-channel-owner',
}

export default { ...OperationalSubTaskTypes, ...GovernanceSubTaskTypes }

export type TaskSubTypes = OperationalSubTaskTypes | GovernanceSubTaskTypes
