import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { apolloReducer } from 'apollo-cache-redux'

import fuse from './fuse'
import ui from './ui.reducer'
import { SharedChannels } from 'src/domain/common'
import channel from './channel.reducer'
import sidebar from './sidebar.reducer'
import activity from './activity.reducer'
import subscriptions from './subscriptions'
import auth from '../../auth/store/reducers'
import favoritesFilter from './favoritesFilter'
import notifications from './notifications.reducers'
import searchVideos from './searchVideos.reducer'
import layoutSidebar from './layoutSidebar.reducer'
import trackListExpanded from './trackslist.reducer'
import translation from '../../translation/store/reducers'
import { Notification } from 'src/gql/queries/getNotifications'
import { modalReducer } from './recordModal.reducer'
import { uploadModalReducer } from './uploadModal.reducer'
import { toolbarReducer } from './toolbar.reducer'

const rootReducer = combineReducers({
  apollo: apolloReducer,
  form,
  ui,
  auth,
  fuse,
  channel,
  sidebar,
  activity,
  translation,
  searchVideos,
  notifications,
  layoutSidebar,
  subscriptions,
  favoritesFilter,
  trackListExpanded,
  modalReducer,
  uploadModalReducer,
  toolbar: toolbarReducer,
})

export type Permissions = {
  readChannel: boolean
  readTrack: boolean
  readVideo: boolean
  readPrivate: boolean
  createChannel: boolean
  createTrack: boolean
  createVideo: boolean
  updateOrganization: boolean
  updateTube: boolean
  updateChannel: boolean
  updateTrack: boolean
  updateVideo: boolean
  updatePermissions: boolean
  archiveChannel: boolean
  archiveTrack: boolean
  archiveVideo: boolean
  createMajorVersion: boolean
  updateStatus: boolean
  addComment: boolean
  archiveComment: boolean
  updateComment: boolean
  assignRoles: boolean
  listOrganizationUsers: boolean
  listChannelUsers: boolean
  whitelistDomains: boolean
  updateOrganizationTags: boolean
  updateUserTags: boolean
  createTask: boolean
  updateSSOUrl: boolean
  generateSecretKey: boolean
  createUser: boolean
  updateUserStatus: boolean
  updateResourcePolicy: boolean
  updateInformationClassification: boolean
  updateAzureAdCertificate: boolean
  updateMasterLanguage: boolean
  forceCheckIn: boolean
  displayEditLink: boolean
  getDetails: boolean
  updateOrganizationAdmins: boolean
  switchToUser: boolean
  'infClassification-read-confidential': boolean
}

export type Settings = {
  preferredLanguage: string
  tooltipDisabled: boolean
  isConfigurationCompleted: boolean
  isFirstLogin: boolean
  autoPlay: boolean
}

export type User = {
  role: string
  data: {
    token: string
    displayName: string
    photoUrl: string
    email: string
    organization: Organization
  }
  userData: {
    userId: string
  }
  email: string
  profile: {
    isMasterAdmin: boolean
    email: string
    lastName?: string
    firstName?: string
    phoneNumber?: string
    jobArea?: string
    jobTitle?: string
    browserLanguage?: string
    profilePic?: string
  }
  originalOrgId: string
  orgId: string
  permissions: Permissions
  settings: Settings
  externalOrgsUserHasAccessTo: any
}

export type Tube = {
  id: string
  pathId: string
  thumbnailOverlay: string
  thumbnailLogo: string
  logo: string
  icon: string
  onboardingVideo: string
  contentTeaserVideo: string
  backgroundImage: string
  color: string
  name: string
}

export type Feature = {
  activatedIn: string[]
  configuration: 'deactivated' | 'tube' | 'channel'
  defaultConfig: 'deactivated' | 'tube' | 'channel'
  description: string
  featureId: string
  status: string
}

export type Organization = {
  id: string
  name: string
  domain: string
  masterLanguage: string
  organizationLanguages: string[]
  website: string
  employeesCount: string | number
  useCases: string[]
  companyRegistration: string
  address: string
  city: string
  state: string
  zipCode: string
  country: string
  activityCode: string
  cognitoIdentifier: string
  samlResponseUrl: string
  hasActiveSubscription: boolean
  azureCertificateUrl: string
  backgroundImage: string
  logo: string
  sharedChannelsFromOthers: SharedChannels[]
  sharedChannelsToOthers: {
    channelId: string
    originOrgId: string
    originTubeId: string
    shareId: string
    destinationPathId: string
  }[]
  infClassifications: {
    [key: string]: string
  }
  pageTags: {
    [key: string]: {
      page: string
      tagGroup: string
      tagValue: string
      featuredVideo: {
        admin?: string
        channelOwner?: string
        contentManager?: string
        contributor?: string
        user?: string
      }
    }[]
  }
  featureFlags: Feature[]
  ssoUrl: string
  whitelistedDomains: string[]
}

export type Auth = {
  user: User
  login: {
    success: boolean
    error: {
      username: string
      password: string
    }
  }
  register: {
    success: boolean
    confirm: boolean
    error: {
      username: string
      password: string
      code: string
    }
  }

  tube: Tube
  organization: Organization
}

export type RecordModal = {
  isModalOpen: boolean
  videoId: string | null
  isRecording: boolean
  version: number | null
  refetch: any
  recordType: string | null
  lang: string | null
  accessTaskId?: string
}
export type UploadModal = {
  videoId: string | null
  isUploading: boolean
  file: any
  isModalOpen: boolean
  version: number | null
  refetch: any
  uploadType: string | null
  lang: string | null
  accessTaskId?: string | null
}

export type ReduxState = {
  form: any
  activity: any
  auth: Auth
  fuse: any
  translation: {
    lang: string
  }
  subscriptions: any
  searchVideos: any
  sidebar: any
  ui: {
    isViewOnlyMode: boolean
  }
  channel: any
  favoritesFilter: any
  notifications: { data: Notification[] }
  trackListExpanded: { channelId: string; ids: string[] }
  modalReducer: RecordModal
  uploadModalReducer: UploadModal
  toolbar: {
    hidetoolbarsearch: boolean
  }
}

export default rootReducer
